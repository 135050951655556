<template>
  <div class="no-found">
    <header>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <img src="@/assets/icons/logo-cmc vision.svg" alt="">
          </div>
        </div>
      </div>
    </header>

    <main>
      <div class="container">
        <div class="row">
          <div class="col-md-4 my-md-auto">
            <div class="no-found--title">
              Oops!
            </div>
            <p class="no-found--subtitle">
              We can't seem to find the page you're looking for.
            </p>
            <router-link :to="{ name: 'dashboard' }">
              <button class="btn btn-outline-light">
                Back to Home
              </button>
            </router-link>
          </div>
          <div class="col-md-8 my-md-auto">
            <img src="@/assets/icons/Illustration-Error-404.svg" class="no-found--image" alt="">
          </div>
        </div>
      </div>
    </main>

    <footer>
      <div class="container">
        <span>
          Vision © 2021 All Rights Reserved by Clear Mortgage Capital, Inc.
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'PageNoFound'
}
</script>

<style lang="scss" scoped>

body {
  background-color: #F6F6F6;
}
.no-found {
  background-color: #F5F5F5;
  min-height: 100vh;
  &--title {
    color: #000000;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: 24px;
  }

  &--subtitle {
    color: #000000;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 24px;
    padding-bottom: 0;
  }

  &--image {
    margin-left: -30px;
    width: 100%;
    height: 100%;
    margin-top: 40px;
  }

  .btn-outline-light {
    max-width: 160px;
    width: 100%;
    height: 48px;
    max-height: 50px;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    opacity: 1;
    border: 1px solid rgba(0,0,0,.3);
    border-radius: 8px;
    margin-top: 30px;
  }

  header {
    padding: 20px 0;
  }

  main {
    margin-top: 95px;
    height: 100%;
  }

  footer {
    position: absolute;
    bottom: 0;
    padding: 40px 0;
    width: 100%;
    background-color: #F5F5F5;

    span {
      color: rgba(0,0,0,.7);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}


</style>
